/** @format */

@import './assets/scss/app.scss';
/** @format */
.modal-xl {
  min-width: 95vw;
}
//.invalid-tooltip {
//  visibility: hidden;
//  opacity:0;
//  position: absolute;
//  top: 100%;
//  z-index: 5;
//  display: none;
//  max-width: 100%;
//  padding: 0.4rem 0.7rem;
//  margin-top: 0.1rem;
//  font-size: 0.7875rem;
//  color: #fff;
//  background-color: rgba(244, 106, 106, 0.9);
//  border-radius: 0.25rem;
//}
//.form-control.is-invalid:hover ~ .invalid-tooltip {
//  visibility: visible;
//  opacity: 1;
//  display: block; /* Change display to block on hover */
//}

td,
th {
  // border: solid 2px black;
  overflow: hidden;

  text-overflow: ellipsis !important;
  // width: fit-content !important;
  min-width: -moz-fit-content !important;
}

.edit-sp-sel-container {
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  max-height: 50vh;
  overflow-y: scroll;

  .edit-sp-selectable {
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.3s ease-in-out;
    label {
      font-weight: 400;
      transition: 0.1s ease-in-out;
      cursor: pointer;
    }
    &:hover {
      label {
        font-weight: 600;
      }
    }
  }
}

// override all buttons
.btn {
  text-transform: capitalize !important;
  * {
    text-transform: capitalize !important;
  }
}

.with-validation[validation-message] {
  position: relative !important;
  .form-control,
  .form-select {
    border-color: #f46a6a;
    -webkit-box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
    box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
    // background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f46a6a%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f46a6a%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
    position: relative !important;
  }

  button {
    background-color: #f46a6a !important;
    border: #f46a6a solid 1px !important;
  }

  &::after {
    content: attr(validation-message) !important;
    top: 80% !important;
    z-index: 9 !important;
    max-width: 100% !important;
    padding: 0.4rem 0.7rem !important;
    margin-top: 0.1rem !important;
    font-size: 0.7875rem !important;
    color: #fff !important;
    background-color: rgba(244, 106, 106, 0.9) !important;
    border-radius: 0.25rem !important;
    position: absolute;
    right: 15px;
    box-shadow: 0 0 10px white;
  }
}

.modal {
  .close {
    background-color: $danger !important;
    border-radius: 5px;
    margin: 5px;
    height: 40px !important;
    width: 40px !important;
    color: black !important;
  }

  .minimize {
    position: absolute;
    top: 0;
    margin: 5px;
    right: 48px;
    height: 44px;
    width: 44px;
    color: $gray-700;
    border-radius: 5px;
    border: 1px solid $secondary;
    background-color: transparent;
  }
}

.modal-minimized {
  position: fixed;
  bottom: 3vh;
  right: 3vw;
  // background: transparentize(rgb(62, 56, 118), 0.3);
  background: white;
  // color: white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  // box-shadow: inset white 0 0 40px, 0 0 40px #032d52;
  box-shadow: 0 0 50px transparentize(#032d52, 0.7);
  background: #1da4c6;
  color: white;
  border: solid transparent 3px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  // animation: scaleIn 0.2s 0s 1 linear;
  // transform-origin:;
  // border: solid transparentize(black, 0.3) 3px;
  animation: shout 1s 0s infinite linear reverse;

  &:hover {
    transform: scale(1.2) rotate(0deg);
    box-shadow: 0 0 10px transparentize(#032d52, 0);
    background: #1da4c6;
    color: white;
    border: solid transparent 3px;
    animation: none;

    // box-shadow:
    // // inset #f46a6a 0 0 10px,
    //   0 0 40px #50a5f1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shout {
  0% {
    box-shadow: 0 0 50px transparentize(#032d52, 0.7);
  }
  60% {
    box-shadow: 0 0 10px transparentize(#032d52, 0);
  }
  100% {
    box-shadow: 0 0 10px transparentize(#032d52, 0.9);
  }
}

.relative {
  position: relative;
}

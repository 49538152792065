/** @format */

.cent-pager {
  //   background-color: red;
  .pager-buttons {
    .pager-fixed-button,
    .pager-nav-button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      min-height: 36px;
      padding: 5px;
      cursor: pointer;
      border-radius: 3px;
      box-shadow: transparentize(black, 0.9) 0 0 10px;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: transparentize(#5b73e8, 0.8);
      }

      &.active {
        background-color: #5b73e8;
        color: white;
        // font-size: large;
      }
    }
  }
}

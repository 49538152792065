/** @format */

.email-pager {
  li.active,
  li.active:hover {
    box-shadow: inset 0px 0 0 #5b73e8;
  }
  .email-pagination {
    display: flex;
    gap: 0.5rem;
    .page-item {
      width: 40px !important;
      height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .page-link {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
      }
    }
  }
}

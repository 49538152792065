/** @format */

.cent-tab {
  margin-bottom: -5px;
  overflow-x: auto;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .cent-tab-pill {
    white-space: nowrap;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-right: 5px;
    // color: #5b73e8;
    border: solid 1px transparent;

    &.active {
      background-color: #5b73e8 !important;
      border: solid 1px #5b73e8;
    }
    // &:hover {
    //   border: solid 1px #5b73e8;
    // }
    // color: black !important;
  }
}

.cent-tab-content {
  border: solid 1px #5b73e8;
  padding: 10px;
  border-radius: 0px;
}

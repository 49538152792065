.vac-media-preview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1233;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #000c;
  outline: none;

  .vac-image-preview {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom:30px;
  }

  .vac-media-preview-container {
    padding: 70px 40px;
    margin: 0 auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .vac-svg-button {
    position: absolute;
    top: 30px;
    right: 30px;
    transform: scale(1.4);
    background: white;
    cursor: pointer;
  }
}

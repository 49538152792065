/** @format */
.cent-vertical-menu {
  // overflow-x: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: fit-content;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* hide scrollbar on Chrome, Safari and Edge */
    width: 5px; /* Adjust the width for vertical scrollbars */
    height: 5px; /* Adjust the height for horizontal scrollbars */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Add a background color to the thumb */
    border-radius: 3px; /* Optional: Add rounded corners to the thumb */
  }

  .cent-sidebar-menu {
    min-width: fit-content;
    overflow-y: auto;
    // overflow-x: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
    // position: absolute;
    // position: relative;
    .cent-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      // opacity: 0.6;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      padding: 6px 0;
      position: relative;
      height: 54px;

      i {
        // transform: scale(1.2);
        margin-left: 15px;
        margin-right: 15px;
        font-size: 20px;
        opacity: 0.8;
      }
      span {
        font-size: 15px;
        // font-weight: 500;
        opacity: 0.8;
      }

      &.with-hover:hover {
        &::after {
          content: attr(centrino-label);
          position: absolute;
          font-size: 14px;
          bottom: -35px;
          background: transparentize(#5b73e8, 0);
          // box-shadow: black 0 0 20px;
          padding: 5px;
          left: 5px;
          z-index: 99999;
          border-radius: 5px;
          animation: fade-in 0.3s 0s linear forwards;
          opacity: 0;
          font-weight: bolder;
          pointer-events: none;
          color: white;
          border: solid white 1px;
          text-transform: capitalize;

          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }

      &:hover {
        background-color: transparentize(black, 0.9);
      }

      &.navigated {
        position: relative;
        &::before {
          content: '';
          width: 4px;
          background-color: #5b73e8;
          height: 100%;
          position: absolute;
        }
        span {
          opacity: 1;
        }
        i {
          opacity: 1;
        }
      }
    }

    .sub-menu-container {
      position: absolute;
      top: 0;
      z-index: 999;
      // background-color: white;
      width: calc(100% - 50px);
      right: 0;
      min-height: 100vh;
      height: max-content;
      padding: 10px;
      box-shadow: 0 0 10px transparentize(black, 0.8);
      // background: #f5f6f8;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .action {
          margin-left: -10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          opacity: 0.5;
          transition: 0.3s ease-in-out;
          i {
            font-size: 20px;
          }
          div {
            font-size: 12px;
          }
          &:hover {
            opacity: 1;
            transform: scale(1.2);
          }
        }
      }
      .title {
        margin-top: 20px;
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 14px;
      }

      .menu-title {
        // background: transparentize(#5b73e8, 0.8);
        // transform: translate(10px, 0);
        // margin-top: 20px;
        font-weight: bolder;
        opacity: 1;
        color: black;
      }

      .menu-item {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        padding: 5px 20px;
        // background: black;
        margin: 0px -10px;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          font-size: 16px;
        }
        &:hover {
          background: transparentize(#5b73e8, 0.9);
        }

        &.disabled {
          opacity: 0.2;
        }

        &.open {
          background: transparentize(#5b73e8, 0.8);
          // color: white;
        }

        &.active {
          color: #5b73e8;
          background: #5b73e020;
          box-shadow: 0 0 10px transparentize(#5b73e8, 0.6);
          // color: white;
        }
      }
      .menu-item-dropdown {
        position: relative;
        padding: 0 10px;
        background: transparentize(#5b73e8, 0.9);
        margin: 0 -10px;
        // .menu-item {
        //   // padding: 5px 30px !important;
        // }
      }
    }
  }
}

/** @format */
@import '../../assets/scss/theme.scss';

@import '../../assets/scss/theme-dark';
@import '../../assets/scss/theme-light';

.nav-tab {
  .nav-tab-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .nav-tab-pill {
      position: relative;
      // min-width: fit-content;
      text-transform: capitalize;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      background: white;
      box-shadow: transparentize($primary, 0.8) 0 0 5px;
      padding: 5px 10px;
      border-radius: 6px;
      color: transparentize(black, 0.3);
      gap: 10px;
      display: flex;
      align-items: center;
      border: solid 1px white;

      &.blocked {
        border: $danger solid 1px;
        color: $danger;
        // font-weight: bolder;
        transition: 0.3s ease-in-out;

        .nav-tab-close {
          background: $danger;
          color: white;
          // font-weight: bolder;
        }

        &:hover {
          border: $danger solid 1px;
          box-shadow: inset 0 0 5px $danger;
        }

        &.navigated {
          background: $danger;
          border: solid 2px $danger;
        }
      }

      .nav-tab-close {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        transition: 0.3s ease-in-out;
        border-radius: 5px;

        &:hover {
          transform: scale(1.5);
        }
      }

      &.has-open-modal::after {
        content: '';
        position: absolute;
        right: -7px;
        top: -7px;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #34c38f;
        border: solid 2px white;
      }

      &:hover {
        border: solid 1px $primary;
        // color: white;

        .nav-tab-close {
          background: $danger;
          color: white;
        }
      }

      &.navigated {
        background-color: $primary;
        color: white;
        font-weight: bolder;
        border: solid 1px $primary;
        .nav-tab-close {
          background: $danger;
          color: white;
          border: solid 1px white;
        }
      }

      &.close-all {
        border: solid $danger 1px;
        font-weight: bolder;
        transition: 0.3s ease-in-out;
        gap: 5px;
        flex-direction: row-reverse;

        span {
          transition: ease-in-out 0.3s;
        }
        i {
          transition: ease-in-out 0.3s;
        }

        &:hover {
          transform: scale(1.2);
          i {
            transform: scale(1.2);
          }
          span {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .nav-content {
    padding: 6px 2px;
    margin-top: 10px;
    min-height: 90vh;
  }
}

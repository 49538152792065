/** @format */

.cent-table {
  th {
    // display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    text-transform: capitalize;
    white-space: nowrap;
    background-color: #5b73e820;
  }

  td {
    white-space: nowrap;
    // border-right: transparent 10px solid;
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
  }

  tr.data-row {
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    // border-bottom: 0.5px transparentize(black, 0.9) solid;
    * {
      transition: 0.2s ease-in-out;
    }
    &:hover {
      background: transparentize(black, 0.8);
    }

    &.selected {
      background: transparentize(#5b73e8, 0.9);
    }
    td {
      min-width: max-content;
    }

    &:nth-child(odd) {
      background-color: transparentize(black, 0.96);
      &.selected {
        background: transparentize(#5b73e8, 0.9);
      }
      &:hover {
        background: transparentize(black, 0.8);
      }
    }

    &:hover {
      .floating-context-menu {
        opacity: 1;
        pointer-events: all;
        transform: translateY(-50%) scale(1);
      }
    }
  }

  tr:first-child th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  tr td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  tr td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

/** @format */

.picker {
  position: relative;

  .picker-dropdown {
    position: absolute;
    box-shadow: transparentize(black, 0.8) 0 0 10px;
    top: 110%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    z-index: 99999;
    bottom: auto; // gap: 5px;
    .picker-item {
      transition: 0.3s ease-in-out;
      padding: 5px;
      cursor: pointer;
      border-radius: 2px;
      &:hover {
        background-color: transparentize(#5b73e8, 0.8);
      }
    }
  }
}

.custom-date-picker {
  width: 100%;
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
}

/** @format */

.report-viewer {
  position: fixed;
  background-color: transparentize($color: black, $amount: 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .report-content {
    position: absolute;
    height: 90vh;
    width: 90vw;
  }
}
